import { BestPoseBindingInfo } from '../../../model/FragmentSearchResult';
import { getFragmentPropsForSuggestion, getMapCaseAndTargetInfo } from './utils';

/**
 * Wrapper for FragmentSearchResult that adds fragment info,
 * annotates per-case data with target info, and calculates summary information.
 */
export class AnnotatedSearchResult {
    static getProjectCasesForSuggestions(suggestions) {
        const projectCases = new Set();

        for (const sugg of suggestions) {
            sugg.projectCases.forEach((pc) => projectCases.add(pc));
        }
        return [...projectCases];
    }

    /**
     * @param {}
     * @param {ActivityThresholdInfo?}
     */
    constructor(suggestion, activityThresholdInfo) {
        this.suggestion = suggestion;
        this.projectCases = Object.keys(suggestion.projectCaseDetails);
        this.fragInfo = getFragmentPropsForSuggestion(this.suggestion, this.projectCases);
        this.refBestPoseInfo = null;
        this.perCaseInfo = {};
        this.updatePerCaseInfo();
        this.updateSummaryInfo(activityThresholdInfo);
    }

    // Passthroughs to the underlying result object
    get origin() { return this.suggestion.origin; }
    get modType() { return this.suggestion.modType; }
    get fragAtomName() { return this.suggestion.fragAtomName; }
    get frags() { return this.suggestion.frags; }
    set frags(value) { this.suggestion.frags = value; }
    get selectedIndex() { return this.suggestion.selectedIndex; }
    set selectedIndex(value) { this.suggestion.selectedIndex = value; }
    get name() { return this.suggestion.name; }
    get mwt() { return this.suggestion.mwt; }
    // bfd-server search results only
    get selectionIDs() { return this.suggestion.selectionIDs; }
    set selectionIDs(value) { this.suggestion.selectionIDs = value; }

    recalculateBestPoses(compareField) {
        this.suggestion.recalculateBestPoses(compareField);
        this.updatePerCaseInfo();
    }

    updatePerCaseInfo() {
        this.refBestPoseInfo = null;
        for (const [projectCase, poseInfo] of Object.entries(this.suggestion.projectCaseDetails)) {
            const newInfo = new AnnotatedBestPoseInfo(poseInfo, projectCase, this);
            if (!this.refBestPoseInfo) {
                this.refBestPoseInfo = newInfo;
            }
            this.perCaseInfo[projectCase] = newInfo;
        }
    }

    getSummaryInfo(thresholdInfo=new ActivityThresholdInfo()) {
        const { poseOrderField, activityField, activityThreshold } = thresholdInfo;
        const activeTargets = [];
        const activeOffTargets = [];
        const inactiveTargets = [];
        const inactiveOffTargets = [];

        for (const projectCase of this.projectCases) {
            const isTarget = this.perCaseInfo[projectCase].targetInfo.isTarget;
            const bindingInfo = this.suggestion.bestPoseForCase(projectCase, poseOrderField);
            if (bindingInfo) {
                let arrayToUse;
                if (bindingInfo.meetsThreshold(activityThreshold, activityField)) {
                    arrayToUse = isTarget ? activeTargets : activeOffTargets;
                } else {
                    arrayToUse = isTarget ? inactiveTargets : inactiveOffTargets;
                }
                arrayToUse.push({ projectCase, bindingInfo });
            } else {
                // What should we do about the no-data cases?
            }
        }

        return {
            activeTargetCount: activeTargets.length,
            activeOffTargetCount: activeOffTargets.length,
            selectivityScore: (
                activeTargets.length + inactiveOffTargets.length - activeOffTargets.length
            ),
            selectivityRatio: (
                (activeTargets.length + inactiveOffTargets.length) / this.projectCases.length
            ),
        };
    }

    /** @param {ActivityThresholdInfo?} */
    updateSummaryInfo(thresholdInfo) {
        this.summaryInfo = this.getSummaryInfo(thresholdInfo);
    }
}

/**
 * BestPoseBindingInfo objects are returned from search results.
 * This class wraps them to include mapCase and target info and provide helpers.
 */
class AnnotatedBestPoseInfo {
    /**
     * @param {BestPoseBindingInfo} bestPoseInfo
     * @param {string} projectCase
     */
    constructor(bestPoseInfo, projectCase, suggestion) {
        this.bestPoseInfo = bestPoseInfo;
        this.projectCase = projectCase;
        this.suggestion = suggestion;
        const { mapCase, targetInfo } = getMapCaseAndTargetInfo(projectCase);
        this.mapCase = mapCase;
        this.targetInfo = targetInfo;
    }

    isActive({ activityThreshold, activityField }=new ActivityThresholdInfo()) {
        return this.bestPoseInfo.meetsThreshold(activityThreshold, activityField);
    }

    isActiveTarget(thresholdInfo) {
        return this.targetInfo.isTarget && this.isActive(thresholdInfo);
    }

    isActiveOffTarget(thresholdInfo) {
        return !this.targetInfo.isTarget && this.isActive(thresholdInfo);
    }

    get bindingScore() { return this.bestPoseInfo.bindingScore; }
    get ligandEfficiency() { return this.bestPoseInfo.ligandEfficiency; }
    get enSolute() { return this.bestPoseInfo.enSolute; }
    get ligandEfficiencyEn() { return this.bestPoseInfo.ligandEfficiencyEn; }
    get enSolv() { return this.bestPoseInfo.enSolv; }
    get exchemPotential() { return this.bestPoseInfo.exchemPotential; }
    get fragStatistics() { return this.bestPoseInfo.fragStatistics; }

    get bindingScoreRatio() {
        const myValue = this.bindingScore;
        const refValue = this.suggestion.refBestPoseInfo.bindingScore;
        if (myValue == null || refValue == null) {
            return null;
        }
        return myValue / refValue;
    }

    filterPoses(projectCase, inclusiveMin, exclusiveMax) {
        return this.suggestion.frags.filter((frag) => {
            const bindingScore = BestPoseBindingInfo.getBindingScore(frag.exchemPotential);
            return frag.projectCase === projectCase
                && (exclusiveMax == null || bindingScore < exclusiveMax)
                && (inclusiveMin == null || bindingScore >= inclusiveMin);
        });
    }

    bucketCount(projectCase, inclusiveMin, exclusiveMax) {
        if (this.bindingScore == null) return null;
        return this.filterPoses(projectCase, inclusiveMin, exclusiveMax).length;
    }

    get poseBucket0_1() { return this.bucketCount(this.projectCase, -1, 0); }
    get poseBucket1_2() { return this.bucketCount(this.projectCase, -2, -1); }
    get poseBucket2_3() { return this.bucketCount(this.projectCase, -3, -2); }
    get poseBucket3_4() { return this.bucketCount(this.projectCase, -4, -3); }
    get poseBucket4_5() { return this.bucketCount(this.projectCase, -5, -4); }
    get poseBucket5_6() { return this.bucketCount(this.projectCase, -6, -5); }
    get poseBucket6_7() { return this.bucketCount(this.projectCase, -7, -6); }
    get poseBucket7_8() { return this.bucketCount(this.projectCase, -8, -7); }
    get poseBucket8_9() { return this.bucketCount(this.projectCase, -9, -8); }
    get poseBucket9_10() { return this.bucketCount(this.projectCase, -10, -9); }
    get poseBucket10_11() { return this.bucketCount(this.projectCase, -11, -10); }
    get poseBucket11_12() { return this.bucketCount(this.projectCase, -12, -11); }
    get poseBucket12_13() { return this.bucketCount(this.projectCase, -13, -12); }
    get poseBucket13_14() { return this.bucketCount(this.projectCase, -14, -13); }
    get poseBucket14_15() { return this.bucketCount(this.projectCase, -15, -14); }
    get poseBucket15_16() { return this.bucketCount(this.projectCase, -16, -15); }
    get poseBucket16_17() { return this.bucketCount(this.projectCase, -17, -16); }
    get poseBucket17_18() { return this.bucketCount(this.projectCase, -18, -17); }
    get poseBucket18_19() { return this.bucketCount(this.projectCase, -19, -18); }
    get poseBucket19_20() { return this.bucketCount(this.projectCase, -20, -19); }
    get poseBucketX_0() { return this.bucketCount(this.projectCase, 0, undefined); }
    get poseBucket20_X() { return this.bucketCount(this.projectCase, undefined, -20); }
}

/**
 * This class manages thresholds for determining pose activity.
 * The values will be passed into `BestPoseBindingInfo.meetsThreshold()`.
 */
export class ActivityThresholdInfo {
    static get DefaultBindingScoreThreshold() { return -7.0; }
    static get DefaultEnSoluteThreshold() { return -19.0; }

    constructor(thresholdInfo={}) {
        const { poseOrderField='bindingScore', activityField='bindingScore' } = thresholdInfo;
        let { activityThreshold } = thresholdInfo;
        if (activityThreshold == null) {
            const defaults = {
                bindingScore: ActivityThresholdInfo.DefaultBindingScoreThreshold,
                enSolute: ActivityThresholdInfo.DefaultEnSoluteThreshold,
            };
            activityThreshold = defaults[activityField] ?? 0;
        }
        this.poseOrderField = poseOrderField;
        this.activityField = activityField;
        this.activityThreshold = activityThreshold;
    }
}
